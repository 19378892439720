import request from '@/utils/request';

// 获取支援列表
export function getCustomerList(data) {
  return request({
    url: '/customer/list',
    method: 'get',
    params: data
  });
}

// 发送支援
export function addCustomer(data) {
  return request({
    url: '/customer/add',
    method: 'post',
    data
  });
}
