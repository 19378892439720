<template>
  <div class="contact-list">
    <div class="contact-item" v-for="item of list" :key="item.id">
      <div class="hd cl">
        <div class="fr">{{$t('Date')}}：{{item.customerDate|formatDate('yyyy-MM-dd')}}</div>
        <div class="title">{{item.title}}</div>
      </div>
      <div class="bd">{{item.content}}</div>
    </div>
    <empty v-if="isEmpty" :title="$t('NoData')"/>
    <el-pagination class="pages"
                   background
                   layout="prev, pager, next"
                   :page-count="totalPages"
                   @current-change="changePage"
                   hide-on-single-page>
    </el-pagination>
  </div>
</template>
<script>
  import Empty from '../../../components/Empty';
  import {getCustomerList} from '../../../api/customer';

  export default {
    name: 'List',
    data() {
      return {
        list: [],
        totalPages: 1,
        isEmpty: false
      };
    },
    components: {Empty},
    created() {
      this.getList();
    },
    methods: {
      getList(page = 1) {
        const data = {page, perPage: 10};
        getCustomerList(data).then(res => {
          const {value} = res;
          this.list = value.list || [];
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
        });
      },
      changePage(page) {
        this.getList(page);
      }
    }
  };
</script>
<style scoped lang="less">
  .contact-list{margin-top:20px;}
  .contact-item{
    margin-bottom:8px;padding:20px;background-color:#f4f4f4;border-radius:4px;
    .hd{padding-bottom:4px;border-bottom:1px dashed #e0e0e0;}
    .bd{margin-top:4px;}
    .ft{margin-top:20px;text-align:right;font-size:0;}
    .title{margin-right:136px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
  }
</style>
