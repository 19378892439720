<template>
  <div class="form-wrap">
    <div class="bd">
      <div class="item">
        <input type="text" autocomplete="off" :placeholder="$t('Title: Please describe your problem')" v-model.trim="title"/>
      </div>
      <div class="item">
        <textarea rows="15" maxlength="500" :placeholder="$t('Content: Please describe your problem content')" v-model.trim="content"></textarea>
      </div>
    </div>
    <div class="ft">
      <button class="button button-primary btn-submit" :disabled="disableSubmit" @click="onSubmit">{{$t('Send')}}</button>
    </div>
  </div>
</template>
<script>
  import {addCustomer} from '../../../api/customer';

  export default {
    name: 'Add',
    data() {
      return {
        title: '',
        content: ''
      };
    },
    computed: {
      disableSubmit: function () {
        return !this.title || !this.content;
      }
    },
    methods: {
      onSubmit() {
        const data = {title: this.title, content: this.content};
        addCustomer(data).then(res => {
          this.$message.success('提交成功');
          Object.assign(this.$data, this.$options.data());
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .form-wrap{
    margin-top:20px;
    .bd{padding:0 20px;background-color:#f4f4f4;}
    .item{
      border-bottom:1px dashed #e0e0e0;
      &:last-child{border-bottom:none;}
    }
    input{width:100%;height:62px;border:none;background-color:transparent;}
    textarea{width:100%;padding:20px 0;border:none;background-color:transparent;}
    .btn-submit{display:block;margin-top:40px;}
  }
</style>
