<template>
  <div class="main">
    <h1 class="h1">{{$t('CustomerSupport')}}</h1>
    <tabs v-model="activeName">
      <tab-pane :label="$t('Support')" name="Add">
        <add/>
      </tab-pane>
      <tab-pane :label="$t('Reply')" name="answer">
        <list/>
      </tab-pane>
    </tabs>
  </div>
</template>
<script>
  import Tabs from '../../components/Tabs/Tabs';
  import TabPane from '../../components/Tabs/TabPane';
  import Add from './components/Add';
  import List from './components/List';

  export default {
    name: 'Contact',
    data() {
      return {
        activeName: 'Add'
      };
    },
    components: {List, Tabs, TabPane, Add}
  };
</script>
